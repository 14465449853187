import React from 'react';
import clsx from 'clsx';

import * as styles from './InnerForm.module.css';
import { Link } from 'gatsby';

const submitFeedback = (status) => {
  if (status === 'submitted') {
    return (
      <div className={clsx(styles.formControl, styles.formFeedback)}>Ihre Anfrage wurde erfolgreich abgeschickt!</div>
    );
  }
  if (status === 'error') {
    return (
      <div className={clsx(styles.formControl, styles.formFeedback, styles.error)}>
        Es kam leider zu einem Fehler beim Absenden des Formulars!
      </div>
    );
  }
  return null;
};

function InputField({ id, type, autoComplete, placeholder, label, touched, values, errors, handleChange, handleBlur }) {
  return (
    <div className={styles.formControl}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        placeholder={placeholder}
        aria-label={label}
        aria-required="true"
        autoComplete={autoComplete}
        type={type || 'text'}
        value={values[id]}
        onChange={handleChange}
        onBlur={handleBlur}
        className={errors[id] && touched[id] ? 'text-input error' : 'text-input'}
      />
      {errors[id] && touched[id] && <div className="input-feedback">{errors[id]}</div>}
    </div>
  );
}

function SelectField({
  id,
  type,
  autoComplete,
  placeholder,
  label,
  touched,
  values,
  errors,
  handleChange,
  handleBlur,
}) {
  return (
    <div className={styles.formControl}>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <label style={{ minWidth: 77 }} htmlFor={id}>
          {label}
        </label>
        <select
          style={{ flexGrow: 1 }}
          id={id}
          placeholder={placeholder}
          aria-label={label}
          aria-required="true"
          autoComplete={autoComplete}
          value={values[id]}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors[id] && touched[id] ? 'text-input error' : 'text-input'}
        >
          <option>Same/Lamborghini</option>
          <option>Delfino/Sprint</option>
          <option>Argon/Crono</option>
          <option>Dorado Natural/Spire Trend</option>
          <option>Dorado/Spire</option>
          <option>Explorer Natural/Strike Trend</option>
          <option>Explorer/Strike</option>
          <option>Virtus/Spark R</option>
          <option>Frutteto/Spire V/S/F</option>
        </select>
      </div>
      {errors[id] && touched[id] && <div className="input-feedback">{errors[id]}</div>}
    </div>
  );
}

export function InnerForm(props) {
  const { values, touched, errors, status, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset } =
    props;

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.ContactForm}
      // className={values.layout || 'small'}
    >
      <div className={styles.column}>
        <div className={styles.nameLine}>
          <InputField {...props} id="firstname" label="Vorname *" autoComplete="given-name" />
          <InputField {...props} id="lastname" label="Nachname *" autoComplete="family-name" />
        </div>
        <InputField {...props} id="email" label="E-Mail *" autoComplete="email" />
        <InputField {...props} id="phone" label="Telefon" autoComplete="tel" />
        <div className={styles.cityLine}>
          <InputField {...props} id="zip" label="PLZ *" autoComplete="postal-code" />
          <InputField {...props} id="city" label="Ort/Stadt *" autoComplete="address-level2" />
        </div>
        <SelectField {...props} id="product" label="Produkt" />
        <div className={styles.formControl}>
          <textarea
            id="message"
            placeholder="Ihre Nachricht ..."
            aria-label="Ihre Nachricht"
            aria-required="true"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.message && touched.message ? 'text-input error' : 'text-input'}
          />
          {errors.message && touched.message && <div className="input-feedback">{errors.message}</div>}
        </div>
        <div className={styles.acceptDatenschutz}>
          <label>
            <input
              id="acceptDatenschutz"
              name="acceptDatenschutz"
              type="checkbox"
              checked={values.acceptDatenschutz}
              onChange={handleChange}
            />
            <span>
              Ich bestätige, die{' '}
              <Link style={{ background: 'transparent' }} to="/datenschutz">
                Datenschutzbestimmungen
              </Link>{' '}
              gelesen und verstanden zu haben
            </span>
          </label>
          {errors.acceptDatenschutz && touched.acceptDatenschutz && (
            <div className="input-feedback">{errors.acceptDatenschutz}</div>
          )}
        </div>
        <div className={styles.formControl}>
          <button type="submit" disabled={isSubmitting}>
            Anfrage senden
          </button>
        </div>
        {values.layout !== 'wide' && submitFeedback(status)}
      </div>
    </form>
  );
}
