import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import * as styles from './ContactForm.module.css';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export const ContactForm = (props) => {
  const { layout, form, mailId, t } = props;
  const invalidEmailMessage = t ? t('invalid_email') : 'Ungültige E-Mail Adresse!';
  const dataPrivacyRequired = t
    ? t('dataprivacy_consent_required')
    : 'Die Datenschutzbestimmungen müssen akzeptiert werden!';

  return (
    <Formik
      initialValues={{
        email: '',
        message: '',
        firstname: '',
        lastname: '',
        product: '',
        phone: '',

        layout,
        acceptDatenschutz: false,
      }}
      validationSchema={Yup.object().shape({
        firstname: Yup.string('').required('Vorname ist ein Pflichtfeld'),
        lastname: Yup.string('').required('Nachname ist ein Pflichtfeld'),
        email: Yup.string().email(invalidEmailMessage).required('E-Mail ist ein Pflichtfeld').trim(),
        phone: Yup.string(),
        zip: Yup.string().required('PLZ ist ein Pflichtfeld'),
        city: Yup.string().required('Ort/Stadt ist ein Pflichtfeld'),
        product: Yup.string(),
        message: Yup.string(),
        acceptDatenschutz: Yup.bool().oneOf([true], dataPrivacyRequired),
      })}
      onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
        fetch('https://webforms.mangoart.at/ezagrar_traktoranfrage.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            mailId: 'traktoranfrage',
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.phone,
            zip: values.zip,
            city: values.city,
            product: values.product || 'Same/Lamborghini',

            message: values.message,
          }),
        })
          .then(() => {
            setSubmitting(false);
            resetForm({
              email: '',
              message: '',
              firstname: '',
              lastname: '',
              phone: '',
              zip: '',
              city: '',
            });
            setStatus('submitted');
          })
          .catch((error) => {
            console.error(error);
            setErrors();
            setSubmitting(false);
            setStatus('error');
          });
      }}
      render={form}
    />
  );
};
